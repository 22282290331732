.project-img-section {
    padding: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.project-img-section h6 {
    color: #105684;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 0px !important;
}

.project-img-section p {
    margin-bottom: 0px;
    color: #9d9d9d;
    font-size: 14px;
}

.project-img-section span {
    color: black;
    margin-bottom: 0px;
    font-size: 15px;
    font-weight: 500;
}

.accordion_subMenu{
    font-size: 14px;
    color: white;
}

.accordion-body{
    padding: 3px 0px;
    padding-left: 26px;
    font-weight: 400;
}

.accordion-body a{
    text-decoration: none !important;
}
.dashboard_details h4{
    color: #105684;
    font-size: 20px;
}
.dashboard_details h3{
color: rgba(0, 0, 0, 0.682);

}