.viewEdit_btn {
    display: flex;
    cursor: pointer;
}

.viewEdit_btn span:nth-child(1){
    color: green;
}

.viewEdit_btn span:nth-child(2){
    color: #426d89;
}

.viewEdit_btn span {
    font-size: 20px;
    margin: 0px 4px;
}
.viewEdit_btn button {
    font-size: 20px;
    margin: 0px 4px;
    border: none !important;
    background: none;
    color: #1677b8;
}

.tblData_export_btn {
    color: white;
    background: #105684;
    border: none;
    border-radius: 4px;
    font-size: 21px;
    height: 35px;
    width: 40px;
}
.table_img_sec{
    height: 60px !important;
    width: 100px !important;
    padding: 10px 0px;
}
.ag-header-cell-text {
    overflow: hidden;
    text-wrap: wrap !important;
    text-overflow: inherit !important;
    font-size: 16px !important;
    color: #105684 !important;
    font-weight: 500 !important;
}

 .ag-cell-value,
.ag-group-value {
    line-height: normal;
    font-size: 15px !important;
    color: black !important;
}

.empty_data_msg{
    box-shadow: 0px 0px 5px #d2d2d2;
    padding: 32px;
    border-radius: 11px;
}

.ag-cell {
    display: flex !important;
}

.table_data_img{
    max-height: 100% !important;
}