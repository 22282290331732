.userDtl_box {
    border-radius: 10px;
    padding: 15px 5px;
    box-shadow: 0px 0px 9px silver;
}

.scroller {
    height: 84vh;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.scroller_scnd{
    height: 65vh;
    overflow: scroll;
    position: relative;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}

.scroller::-webkit-scrollbar, .scroller_scnd::-webkit-scrollbar {
    display: none;
}

.view_dtails {
    text-align: center;
    margin: 7px 0px;
}

.project_img_section {
    height: 80px !important;
    width: 100%;
    display: flex;
    justify-content: center;
}

.project_img {
    box-shadow: 0px 5px 30px #12113636;
    height: 100% !important;
    padding: 2px;
    width: 100px !important;
    border-radius: 10px;
}

.view_dtails h4 {
    margin-bottom: 0px;
    font-size: 20px !important;
    margin-top: 5px;
    color: #105684;
}

.view_dtails img {
    height: auto;
    width: 65px;
    border: 2px solid #484848;
}

.view_dtails .userImg {
    height: 65px;
    width: 65px;
    border: 2px solid #484848;
    border-radius: 50%;
}

.view_dtails .date {
    font-weight: 500;
    color: rgb(91, 90, 90);
    font-size: 14px;
}
.view_dtails h6{
    margin-bottom: 0px;
}

.view_dtails .country {
    font-size: 14px;
    font-weight: 500;
}


.head {
    font-weight: 500;
    font-size: 16px;
    color: #105684;
    margin: 0px !important;
    margin-top: 5px !important;
}

.body {
    font-size: 16px;
    /* color: rgb(90, 90, 90); */
    /* color: black; */
}

.nav-tabs {
    justify-content: space-around;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    border: none !important;
    border-bottom: 2px solid #105684 !important;
}

.nav-link {
    color: #105684 !important;
    font-weight: 500;
    font-size: 18px;
}

.nav-tabs .nav-link{
    border: none;
}

.nav-tabs .nav-link:hover {
    border: none;
}

.gaganHomes_dataList {
    margin: 0px 5px;
}

