/* PROPERTY-LOGIN & OTP CSS START */

.login-side {
    background-color: #105684;
    height: 100vh;
    clip-path: ellipse(100% 135% at 0% 50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .login-details {
    display: flex;
    justify-content: center;
    /* margin-top: 170px; */
  }
  
  .logo-img-section {
    width: 150px;
    margin-left: 30px;
  }
  
  .logo-img {
    height: 100% !important;
  }
  
  .login-details1 {
    margin-top: 30px;
    text-align: center;
    font-weight: 500;
  }
  
  .login-details1 span {
    color: #EF3D50;
    cursor: pointer;
  }
  
  .login-input {
    /* background-color: #e6e8ec !important; */
    margin-top: 20px;
    border-radius: 50px !important;
  }
  
  .login-input:focus {
    border-color: none !important;
    box-shadow: none !important;
    border-color: black !important;
  }
  
  .in-btn {
    margin-top: 30px;
    /* float: right; */
    width: 100px;
    font-weight: 500 !important;
    background-color: #105684 !important;
    color: white !important;
    border-radius: 50px !important;
  }
  
  .in-btn:focus {
    outline: 0 !important;
    box-shadow: none !important;
  }
  
  .inputbox {
    width: 50px !important;
    height: 50px !important;
    filter: drop-shadow(0px 12px 40px rgba(0, 0, 0, 0.078));
    border: 1px solid #D0D0D0;
    /* background-color: #e6e8ec; */
    border-radius: 50%;
  }
  
  .otp-margin {
    margin-left: 45px;
  }
  
  /* .resend {
      margin-top: 5px;
    } */
  
  /* PROPERTY-LOGIN & OTP CSS END */
  
  /* RESPONSIVE CSS FOR MOBILE  START*/
  
  @media only screen and (max-width: 767px) {
    .login-details {
      display: flex;
      justify-content: center;
      margin-top: 180px;
    }
  }
  
  /* RESPONSIVE CSS FOR MOBILE  END*/