.wrapper {
  box-shadow: 0px 0px 5px #d2d2d2;
  padding: 28px;
  border-radius: 11px;
}

/* .gh_formWrapper {
  margin-bottom: 4px !important;
  margin-top: 3px !important;
} */

.gh_formWrapper {
  margin: 6px 0px;
}

.gh_btn {
  background: #14659b !important;
  color: white !important;
}
.book_prty{
  font-size: 12px !important;
  background: #14659b !important;
  color: white !important;
  padding: 3px 8px !important;
}
.reject_btn{
  background: #EF3D50 !important;
  color: white !important;
}

.gh_label {
  font-weight: 500;
  font-size: 14px;
}

.gh_formWrapper label {
  font-size: 14px;
  margin-bottom: 4px;
}

.col-4.gh_formWrapper {
  margin: 6px 0px;
}

.gh_form:focus {
  color: var(--bs-body-color);
  background-color: none !important;
  border-color: #a3a3a3 !important;
  outline: 0;
  box-shadow: 0px 0px 7px #cbc9c9 !important;
}

.error_msg {
  font-size: 14px;
  color: red;
}

.scnd_header {
  background: #f8f9fa;
  padding: 13px 15px;
  margin: 6px 0px;
}

.scnd_header .icon1 {
  color: gray;
  font-weight: 600;
}

.scnd_header .icon2 {
  color: #14659b;
  font-weight: 600;
}

/* data table css start*/
.ag-cell-value,
.ag-group-value {
  line-height: normal;
}

.ag-cell {
  display: flex;
}

/* data table css end */

.accordion-button::after {
  background-image: var(--bs-body-color) !important;
}

.accordion {
  --bs-body-color: url('../public//arrw.gh.svg') !important;
}

.tree_child span {
  /* background-color: #14659b !important; */
  /* color: white !important; */
  /* padding: 0px 20px 6px 20px; */
  border-radius: 10px;
  margin-bottom: 10px;
}

.css-1vs3jbz div span {
  background-color: #14659b !important;
  color: white !important;
  padding: 0px 20px 6px 20px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.status_availabel {
  background-color: rgba(58, 58, 232, 0.848) !important;
  color: white !important;
  padding: 2px 10px 4px 10px;
  margin-bottom: 0px;
  font-size: 14px;
  display: flex;
  border-radius: 10px;
}

.status_hold {
  background-color: rgba(240, 174, 51, 0.804) !important;
  color: white !important;
  padding: 2px 10px 4px 10px;
  margin-bottom: 0px;
  font-size: 14px;
  display: flex;
  border-radius: 10px;
}
.status_rejected{
  background-color: #EF3D50!important;
  color: white !important;
  padding: 2px 10px 4px 10px;
  margin-bottom: 0px;
  font-size: 14px;
  display: flex;
  border-radius: 10px;
}
.status_booked {
  background-color: #47BB4C !important;
  color: white !important;
  padding: 2px 10px 4px 10px;
  margin-bottom: 0px;
  font-size: 14px;
  display: flex;
  border-radius: 10px;
}

.status_rejected {
  background-color: #EF3D50 !important;
  color: white !important;
  padding: 2px 10px 4px 10px;
  margin-bottom: 0px;
  font-size: 14px;
  display: flex;
  border-radius: 10px;
}
.psw_icon {
  position: absolute;
  top: 35px;
  right: 20px;
}
.exelSheet_icon{
  background-color: #105684;
  padding: 5px 5px 7px 5px;
  border-radius: 5px;
}


/* @media screen and (min-device-width: 200px) and (max-device-width: 992px) { 
  
} */

@media screen and (max-width: 992px) {
  .margin-all-page {
    margin-left: 0px !important;
  }
}

.react-datepicker-wrapper{
  display: block !important;
}

.totl_review_section{
  padding: 20px 15px;
}
.totl_review_section p{
  margin-bottom: 0px !important;
  color: #105684;
  font-size: 18px;
  font-weight: 500;
}
.totl_review_section span{
  margin-bottom: 0px !important;
  color: black;
  font-size: 18px;
  font-weight: 500;
}
.status_review_section{
  padding: 20px 15px;
}
.status_review_section p{
  margin-bottom: 0px !important;
  color: #105684;
  font-size: 16px;
  font-weight: 500;
}

.tree_child{
  overflow-x: scroll !important;
}
.tree_child .css-1pqvl61{
  margin-bottom: 10px !important;
}
.userLoginTree{
  color:#EF3D50;
  font-size: 16px;
  font-weight: 500;
}