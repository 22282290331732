.gagan_privacy_header {
    height: 70px;
    padding: 10px 15px 15px 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    background-color: #105684;
}
.sidebar-logo-section{
    height: 50px;
}
.sidebar_logo {
    width: 130px;
    height: 100%;
}
.CRM_view {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 9px 0px, rgba(0, 0, 0, 0.20) 0px -1px 9px 0px;
    padding: 15px 0px;
    border-radius: 10px;
    margin-top: 10px;
    height: 80vh;
}
