/* PROPERTY-HEADER CSS START */

.user-img {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 50% !important;
    height: 35px !important;
  }
  
  #property-header {
    height: 75px;
    padding: 20px;
    margin-right: 3px;
    z-index: 99999;
  }
  
  .dashboard-heading {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 0px !important;
  }
  
  .property-header-margin {
    margin-left: 225px;
    transition: 1s;
  }
  
  .sidebar-btn {
    margin-left: 20px;
  }
  
  .header-logo-section {
   
      height: 50px !important;
      width: 180px !important;
      display: flex;
      justify-content: center;
  }
  
  .header-logo {
    height: 100% !important;
    display: flex;
    align-items: center !important;
  }

  .drop_btn {
    /* box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.2); */
    border: none !important;
    background-color: #105684 !important;
    /* color: ; */
    font-size: 14px !important;
  }
  
  .profile_drop button {
    font-size: 16px !important;
    font-weight: 600;
  }
  
  .profile_drop button:focus {
    background-color: none !important;
  }
  
  /* PROPERTY-HEADER CSS END */
  
  /* PROPERTY-SIDEBAR CSS START  */
  
  #dashboard-sidebar {
    position: fixed;
    width: 225px !important;
    padding: 0px !important;
    z-index: 999;
    animation-name: dev;
    animation-duration: 0.9s;
  }
  
  .profile-sidebar {
    width: 240px !important;
    padding: 0px !important;
  }
  
  @keyframes dev {
    from {
      transform: translateX(-220px);
    }
  
    to {
      transform: translateX(0px);
    }
  }
  
  .profile-header {
    padding: 20px 25px 10px 25px !important;
  }
  
  
  .profile-sidebar-body {
    background-color: #105684 !important;
    clip-path: ellipse(200% 100% at 10% 102%);
    padding: 30px 30px;
    /* overflow-y: scroll; */
    height: 95vh;
  }
  
  .profile-sidebar-body::-webkit-scrollbar {
    width: 4px !important;
  }
  
  .profile-sidebar-body::-webkit-scrollbar-track {
    background: #105684 !important;
  }
  
  .profile-sidebar-body::-webkit-scrollbar-thumb {
    background: white;
  }
  
  .profile-sidebar-body h2 {
    margin-top: 7px;
  }
  
  .new-accordion {
    background-color: #105684 !important;
    border: none !important;
  }
  
  /* .accordion-button:not(.collapsed) {
    background-color: #105684 !important;
    color: white !important;
    font-weight: 600 !important;
    font-size: 18px !important;
  } */
  
  .acc-btn {
    background-color: #105684 !important;
    color: white !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    border: none !important;
    padding: 0px !important;
  }
  
  .acc-btn:focus {
    box-shadow: none !important;
  }
  
  .accordion {
    --bs-accordion-border-color: none !important;
  }

  .profile_drop {
    position: absolute !important;
    width: 15rem !important;
    inset: 10px 30px auto auto !important;
    padding: 20px !important;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.2);
    border: none !important;
    --bs-dropdown-link-active-bg: none !important;
    color: #121136 !important;
    margin: 0px !important;
    transform: translate3d(-15.2px, 48px, 0px) !important;
  }
  
  .profile_drop li span {
    text-align: left !important;
    font-size: 16px !important;
    /* margin-top: 10px; */
    cursor: pointer;
    padding: 5px;
    color: #105684;
     font-weight: 600;
  }
  
  .profile_drop p {
    margin-bottom: 0px;
    text-align: center;
    font-size: 18px !important;
    font-weight: 500;
    color: #105684;
  }
  
  /* .accordion-button::after {
    background-image: url(../../../public/Images/angle-down-solid.svg) !important;
  } */
  
  /* .accordion-button:not(.collapsed)::after {
    background-image: url(../../../public/Images/angle-down-solid.svg) !important;
    transform: var(--bs-accordion-btn-icon-transform);
  } */
  
  /* .accordion-body {
    padding: 0px !important;
  } */
  
  /* .acc-list {
    padding-left: 0px;
    margin-top: 5px !important;
    margin-bottom: -5px !important;
  }
  
  .acc-list li {
    font-size: 16px !important;
    font-weight: 600;
    list-style-type: none;
    margin-left: 5px;
  }
  
  .acc-list li a {
    color: white;
  } */
  
  /* PROPERTY-SIDEBAR CSS END  */
  
  
  /* PROPERTY-SIDEBAR RESPONSIVE CSS START  */
  
  /* RESPONSIVE CSS FOR TAB START */
  
  @media only screen and (min-width: 768px) and (max-width: 992px) {
  
    .property-header-margin {
      margin-left: 0px !important;
    }
  
  }
  
  /* RESPONSIVE CSS FOR TAB END */
  
  /* RESPONSIVE CSS FOR MOBILE  START */
  
  @media only screen and (max-width: 767px) {
  
  
    .property-header-margin {
      margin-left: 0px !important;
    }
  
    #property-header {
      margin-right: 0px;
    }
  
    .sidebar-btn {
      margin-left: 10px;
    }
  
    .profile-sidebar {
      width: 230px !important;
      padding: 0px !important;
    }
  
    .acc-btn {
      margin-top: 15px;
    }
  }

  .margin-all-page {
    margin-left: 239px;
    animation-name: dev;
    animation-duration: 0.9s;
}
  
  /* RESPONSIVE CSS FOR MOBILE  END */
  
  
  /* PROPERTY-SIDEBAR RESPONSIVE CSS END */